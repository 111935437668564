<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getBusinesses"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <router-link v-if="routeName !== 'agency.businesses.index'" :to="{ name: 'agency.businesses.index', query: { 'create_lead': true } }" class="btn btn-sm btn-success mr-4">Create Customer</router-link>
              <router-link v-if="routeName !== 'agency.businesses.index'" :to="{ name: 'agency.businesses.index', query: { 'create_customer': true } }" class="btn btn-sm btn-info mr-4">Create Customer Manually</router-link>
              <vue-json-to-csv :json-data="customers" :csv-title="csvTitle()">
                <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
              </vue-json-to-csv>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getBusinesses"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <div class="table-responsive mb-0 customer-table">
                <b-table
                  :items="customers"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(active)="data">
                    <span v-if="data.item.active">Active</span>
                    <span v-else>Inactive</span>
                  </template>
                  <template v-slot:cell(connected)="data">
                    <span v-if="data.item.location" class="text-success font-size-20"><i class="uil uil-check" /></span>
                    <span v-else class="text-danger font-size-20"><i class="uil uil-times" /></span>
                  </template>
                  <template v-slot:cell(actions)="data">
<!--                    <a :href="data.item.google_maps_url" class="mr-1" target="_blank"><img style="max-height: 30px" src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png"></a>-->
                    <router-link
                      :to="{
                        name: 'agency.customers.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-success btn-sm ml-1"
                    >
                      Edit
                    </router-link>
<!--                    <router-link :to="{
                      name: 'agency.businesses.reviews',
                      params: { businessId: data.item.id },
                    }" class="btn btn-info btn-sm ml-1">Reviews</router-link>-->
                    <b-button v-if="false" variant="info" size="sm" class="ml-2" @click="updateShipping(data.item)">
                      <i class="uil uil-truck"></i>
                    </b-button>
                    <b-button
                        v-if="data.item.email"
                        variant="primary"
                        size="sm"
                        title="Sign In"
                        :disabled="loadingImpersonate"
                        class="ml-2"
                        @click="impersonate(data.item.user_id)"
                    >
                      <b-spinner v-if="loadingImpersonate" small />
                      <i v-else class="uil uil-sign-out-alt"></i>
                    </b-button>
                    <b-button
                      variant="link"
                      title="Delete"
                      size="lg"
                      class="btn-delete"
                      :disabled="loadingRemove === data.item.id"
                      @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalCustomers }} customers
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalCustomers"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showShippingModal" :hide-footer="true" :title="`Add/Update Shipping for ${shippingModalOwner.first_name} ${shippingModalOwner.last_name}`" @hidden="resetShippingModal">
      <label>Tracking Number</label>
      <b-input v-model="shippingModalOwner.tracking_number" type="text" />
      <button class="btn btn-success mt-2 w-100" type="button" @click="addShipping">
        <b-spinner v-if="shippingModalOwner.working" small />
        <span v-else>Submit</span>
      </button>
      <table v-if="shippingModalOwner.tracking_numbers.length" class="table">
        <thead>
        <tr>
          <th>Existing Tracking Information</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(tracking,i) in shippingModalOwner.tracking_numbers" :key="i">
          <td>
            <label>Created: {{tracking.front_date}}</label>
            <div class="d-flex">
              <b-input v-model="tracking.tracking_number" />
              <button type="button" class="btn btn-info ml-1" @click="updateTrackingNumber(tracking)">
                <b-spinner v-if="tracking.is_updating" small />
                <span>Update</span>
              </button>
              <button type="button" class="btn btn-danger ml-1" @click="deleteTrackingNumber(tracking)">
                <b-spinner v-if="tracking.is_deleting" small />
                <i v-else class="uil uil-trash-alt" />
              </button>
            </div>
            <div v-if="tracking.tracking_number === null" class="text-danger">
              Not Shipped
            </div>
            <div v-else>
              <div>
                Carrier: {{tracking.carrier}}
              </div>
              <a :href="tracking.tracking_url" target="_blank">Track: {{tracking.tracking_number}}</a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Customer">
      <p v-if="selectedBusiness"
        >Are you sure you want to delete business "{{
          selectedBusiness.business_name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showWelcomeModal" size="lg" title="Welcome">
      <div class="text-center">
        <h4 class="text-success">Thanks For Signing Up For ReplyEngine</h4>
        <p>
        </p>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="success" @click="showWelcomeModal = false"
          >Ok</b-button
        >
      </template>
    </b-modal>
    <b-modal v-model="showCreateLeadModal" size="lg" :hide-footer="true" :hide-header="true" modal-class="widget-modal">
      <div class="text-center">
        <h4>Create Lead</h4>
        <p>
          <reply-engine-generator
              v-if="user && user.agency"
              ref="rlg"
              :search-text="widgetDefaultText"
              class="widget-creator"
              :agency-id="user.agency.id"
              :starting-step="1"
              header-text="Search For Your Business"
              background-color="#ffffff"
              main-text-color="#222222"
              primary-button-background-color="#34a853"
              primary-button-text-color="#ffffff"
              :google-api-key="user.agency.landing_page.admin_settings.google_api_key"
              place-holder="Search"
              :manually-created="true"
              button-text="Create Lead"
              :force-busy="createIsBusy"
              @prospectCreated="prospectCreated"
          ></reply-engine-generator>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
import  ReplyEngineGenerator from '../../../../embed-form/src/components/Form'
export default {
  components:{
    VueJsonToCsv,
    'reply-engine-generator': ReplyEngineGenerator
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'small_date', label: 'Date', sortable: true },
          { key: 'business_name', label: 'Business', sortable: true },
          { key: 'full_name', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'connected', label: 'Connected', class: 'text-center'},
          { key: 'phone_number', label: 'Phone', sortable: true },
          { key: 'active', label: 'Status', sortable: true },
          { key: 'actions', label: 'Actions', class:'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          prospect_status:'all',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showWelcomeModal: false,
      createIsBusy:false,
      widgetDefaultText:'',
      showCreateLeadModal:false,
      showShippingModal:false,
      shippingModalOwner: {
        first_name:'',
        last_name:'',
        id:null,
        tracking_number:'',
        working:false,
        tracking_numbers:[]
      }
    }
  },

  computed: {
    customers() {
      return this.$store.getters['customers/all'] || []
    },

    totalCustomers() {
      return this.$store.getters['customers/total']
    },

    reload() {
      return this.$store.getters['customers/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    routeName(){
      return this.$route.name;
    }
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.$store.dispatch('customers/setCurrentPage', 'index')
  },

  mounted() {
    this.getBusinesses();

    if (this.user.agency.is_new) {
      this.showWelcomeModal = true

      this.$store.dispatch('agency/toggleIsNew', this.user.agency)
    }

    if(window.location.search.includes('create_lead')){
      this.showCreateLeadModal = true;
      let query = Object.assign({}, this.$route.query);
      delete query.create_lead;
      if(query.hasOwnProperty('business_name')) {
        this.widgetDefaultText = decodeURI(query.business_name);
        delete query.business_name;
      }
      this.$router.replace({ query });
    }
  },

  methods: {
    updateTrackingNumber(tracking){
      tracking.is_updating = true;
      this.$store
          .dispatch('auth/updateTrackingNumber', tracking)
          .then((resp) => {
            tracking.is_updating = false;
          })
          .catch(() => {
            tracking.is_updating = false;
          })
    },
    deleteTrackingNumber(tracking){
      tracking.is_deleting = true;
      this.$store
          .dispatch('auth/deleteTrackingNumber', tracking.id)
          .then((resp) => {
            this.shippingModalOwner.tracking_numbers = resp.data.tracking_numbers;
          })
          .catch(() => {

          })
    },
    resetShippingModal(){
      this.shippingModalOwner = {
        first_name:'',
        last_name:'',
        id:null,
        tracking_number:'',
        working:false,
        tracking_numbers:[]
      };
    },
    updateShipping(business){
      this.shippingModalOwner.id = business.user_id;
      this.shippingModalOwner.first_name = business.first_name;
      this.shippingModalOwner.last_name = business.last_name;
      this.showShippingModal = true;
      this.$store
          .dispatch('auth/getUsersTrackingNumber', this.shippingModalOwner.id)
          .then((resp) => {
            this.shippingModalOwner.tracking_numbers = resp.data.tracking_numbers;
          })
          .catch(() => {

          })
    },
    addShipping(){
      this.shippingModalOwner.working = true;
      this.$store
          .dispatch('auth/addTrackingNumber', this.shippingModalOwner)
          .then((resp) => {
            this.shippingModalOwner.working = false;
            this.shippingModalOwner.tracking_numbers = resp.data.tracking_numbers;
            this.shippingModalOwner.tracking_number = '';
          })
          .catch(() => {
            this.shippingModalOwner.working = false
          })
    },
    prospectCreated(business){
      this.showCreateLeadModal = false;
      this.createIsBusy = false;
      this.$router.push({
        name: 'agency.businesses.edit',
        params: { id: business.id },
      })
    },
    csvTitle(){
      return 'reply-engine-export-'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBusinesses()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true

      this.$store
        .dispatch('customers/getAll', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then((res) => {
          this.loadingBusinesses = false
        })
        .catch(() => {
          this.loadingBusinesses = false
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedBusiness.id
      this.$store
        .dispatch('business/archive', this.selectedBusiness.id)
        .then(() => {
          this.loadingRemove = null
          this.getBusinesses()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  .status-filter{
    text-transform: capitalize;
  }
</style>
<style>
.pac-container.pac-logo {
  z-index: 10000000;
}
.customer-table td.actions {min-width:200px; max-width: 200px;}
</style>
